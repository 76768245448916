export const TOKEN = "token";
export const ROLES = [
  "Executive Admin",
  "Branch Admin",
  "Front Desk Officer",
  "Accountant",
  "Embalmer",
];

export const EXECUTIVE_ROUTES = [
  {
    title: "Dashboard",
    route: "/app/dashboard",
    icon: "app-grid",
    subRoutes: [],
  },
  {
    title: "Users",
    route: "/app/staffs",
    icon: "user",
    subRoutes: [],
  },
  {
    title: "Branches",
    route: "/app/branches",
    icon: "chart",
    subRoutes: [],
  },
  {
    title: "Inventory",
    route: "/app/inventory",
    icon: "chart",
    subRoutes: [],
  },
  {
    title: "System Settings",
    route: "/app/settings",
    icon: "chart",
    subRoutes: [],
  },
  {
    title: "System Log",
    route: "/app/system-log",
    icon: "setting",
    subRoutes: [],
  },
];

export const ADMIN_ROUTES = [
  {
    title: "Dashboard",
    route: "/app/dashboard",
    icon: "app-grid",
    subRoutes: [],
  },
  {
    title: "Users",
    route: "/app/staffs",
    icon: "user",
    subRoutes: [],
  },
  {
    title: "Services",
    route: "/app/products",
    icon: "chart",
    subRoutes: [],
  },
  // {
  //   title: "Management Report",
  //   route: "/app/report",
  //   icon: "setting",
  //   subRoutes: [],
  // },
  {
    title: "System Settings",
    route: "/app/settings",
    icon: "setting",
    subRoutes: [],
  },
];

export const DESK_ROUTES = [
  {
    title: "Dashboard",
    route: "/app/dashboard",
    icon: "app-grid",
    subRoutes: [],
  },
  {
    title: "Enquiry",
    icon: "chart",
    route: "/app/enquiry",
    subRoutes: [
      {
        title: "Enquiry",
        icon: "chart",
        route: "/app/enquiry",
      },
      {
        title: "Removal Log",
        icon: "chart",
        route: "/app/enquiry/log",
      },
    ],
  },
  {
    title: "Admission",
    icon: "ticket",
    route: "/app/addmission",
    subRoutes: [
      {
        title: "Admission",
        icon: "chart",
        route: "/app/addmission",
      },
      // {
      //   title: "Corpse Viewing",
      //   icon: "chart",
      //   route: "/app/addmission/corpse-viewing",
      // },
      {
        title: "Corpse Release",
        icon: "chart",
        route: "/app/addmission/corpse-release",
      },
      // {
      //   title: "Cremation",
      //   icon: "chart",
      //   route: "/app/addmission/cremation",
      // },
    ],
  },
  {
    title: "Repatriation",
    route: "/app/repatriation",
    icon: "activity",
    subRoutes: [],
  },
];

export const INVENTORY_ROUTES = [
  {
    title: "Dashboard",
    route: "/app/dashboard",
    icon: "app-grid",
    subRoutes: [],
  },
  {
    title: "Inventory",
    route: "/app/inventory",
    icon: "chart",
    subRoutes: [],
  },
  {
    title: "Services",
    route: "/app/products",
    icon: "chart",
    subRoutes: [],
  },
  {
    title: "Fridge",
    route: "/app/fridge",
    icon: "chart",
    subRoutes: [],
  },
];
