import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userDetail: {},
    branches: [],
    roles: [],
    permissions: [],
    settings: [],
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      state.userDetail = payload;
    },
    SET_BRANCHES(state, payload) {
      state.branches = payload;
    },
    SET_ROLES(state, payload) {
      state.roles.push(payload);
    },
    RESET_ALL_ROLES(state) {
      state.roles = [];
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
  },
  actions: {},
  modules: {},
});
