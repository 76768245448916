import Vue from "vue";
import VueRouter from "vue-router";

import { TOKEN } from "@/utils/constants";

function guardRoute(to, from, next) {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    next();
  } else {
    next("/");
  }
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/app/dashboard",
    component: () => import("../views/Home.vue"),
    beforeEnter: guardRoute,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/app/staffs",
        name: "Staffs",
        component: () => import("../views/staffs/Index.vue"),
      },
      {
        path: "/app/inventory",
        name: "Inventory",
        component: () => import("../views/Inventory.vue"),
      },
      {
        path: "/app/products",
        name: "Products",
        component: () => import("../views/Products.vue"),
      },
      {
        path: "/app/fridge",
        name: "Fridge",
        component: () => import("../views/Fridge.vue"),
      },
      {
        path: "/app/branches",
        name: "Branches",
        component: () => import("../views/branch/Index.vue"),
      },
      {
        path: "/app/addmission/corpse-viewing",
        name: "CorpseViewing",
        component: () => import("../views/addmission/CorpseViewing.vue"),
      },
      {
        path: "/app/addmission/corpse-release",
        name: "CorpseRelease",
        component: () => import("../views/addmission/CorpseRelease.vue"),
      },
      {
        path: "/app/addmission/cremation",
        name: "CorpseCremation",
        component: () => import("../views/addmission/CorpseCremation.vue"),
      },
      {
        path: "/app/addmission/:action?",
        name: "Addmission",
        component: () => import("../views/addmission/Index.vue"),
      },
      {
        path: "/app/enquiry",
        name: "Enquiry",
        component: () => import("../views/enquiry/Index.vue"),
      },
      {
        path: "/app/enquiry/log",
        name: "RemovalLog",
        component: () => import("../views/enquiry/RemovalLog.vue"),
      },
      {
        path: "/app/repatriation",
        name: "Repatriation",
        component: () => import("../views/Repatriation.vue"),
      },
      {
        path: "/app/settings",
        name: "Settings",
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/app/system-log",
        name: "SystemLog",
        component: () => import("../views/SystemLog.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "logIn",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "welcomePage",
    beforeEnter: guardRoute,
    component: () => import("../views/auth/Register.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
