/* eslint-disable no-useless-escape */
import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  email,
  max,
  min,
  numeric,
  required,
  length,
  alpha_num,
} from "vee-validate/dist/rules";

extend("email", email);
extend("max", max);
extend("min", min);
extend("length", length);
extend("numeric", numeric);
extend("required", required);
extend("alpha_num", alpha_num);
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("alpha_num", {
  validate(value) {
    const reg = /(?:[^`!@#$%^&*\-_=+'\/.,]*[`!@#$%^&*\-_=+'\/.,]){2}/i;
    return reg.test(value);
  },
  message: "{_field_} must contain a minimum of two special characters",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
